body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components{
  .btn{
    @apply border mt-8 font-medium px-12 py-2 border-gray-400 bg-gray-200 rounded-md text-gray-800 hover:bg-transparent hover:text-[#1C0A00]

  }

  .btn-invt{
    @apply border mt-8 font-medium px-12 py-2 border-gray-600 bg-gray-800 rounded-md text-gray-100 hover:bg-transparent hover:text-gray-800

  }

  .too-small{
    @apply text-xs leading-3 text-gray-600 dark:text-gray-500 mt-4
  }
  .inpt-seller{
    @apply w-full p-2 border border-gray-400 rounded-sm
  }

  .dashboard-navigation{
    @apply lg:hidden h-6 w-6 outline-gray-800
  }
}